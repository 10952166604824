<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">All available Gift Cards</h2>
          <p class="mb-0">Find below the available gift cards</p>
        </div>

        <div class="btn-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click="RefreshData"
          >
            Reload
          </button>
        </div>
      </div>
      <div class="table-settings mb-4">
        <div class="row align-items-center justify-content-between">
          <div class="col col-md-6 col-lg-3 col-xl-4">
            <div class="input-group">
              <span class="input-group-text"
                ><span class="fas fa-search"></span
              ></span>
              <input
                type="text"
                class="form-control"
                placeholder="Find Gift Cards"
                aria-label="Find Gift Cards"
                disabled
              />
            </div>
          </div>
          <div class="col-4 col-md-2 col-xl-1 pl-md-0 text-right">
            <CountSelector
              v-if="data"
              v-model="data.meta.per_page"
              @updated="RefreshData"
              class="mr-2"
            />
            <router-link to="/cards/create" class="btn btn-dark">
              <i class="fas fa-plus"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0"
      >
        <table class="table table-hover min-h-400">
          <thead>
            <tr>
              <th>#</th>
              <th>Img</th>
              <th>Name</th>
              <th>Uid</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody v-if="data">
            <AvailableCardsRow
              v-for="(dt, index) in data.cards"
              :key="index"
              :dt="dt"
            />
          </tbody>
        </table>
        <Pagination
          v-if="data"
          v-model="data.meta.page"
          :currentPage="data.meta.page"
          :totalRecord="data.meta.count"
          :dataCount="data.meta.per_page"
          @updated="RefreshData"
        />
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import AvailableCardsRow from "@/views/cards/constituent/AvailableCardsRow.vue";

export default defineComponent({
  name: "AvailableGiftCards",
  components: {
    AvailableCardsRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/`,
          (data.value as any)?.meta ?? {}
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
    };
  },
});
</script>

<style scoped></style>

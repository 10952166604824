<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">
          Loading ...
        </span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">
          {{ dt.id }}
        </a>
      </td>
      <td>
        <span class="font-weight-normal">
          <img :src="dt.image" height="50" :alt="dt.name" />
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          <router-link
            :to="`/cards/${dt.id}/entries?uid=${dt.uid}`"
            class="text-blue"
          >
            {{ dt.name }}
          </router-link>
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.uid }}
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.created_at }}
        </span>
      </td>
      <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <router-link
              :to="`/cards/${dt.id}/entries?uid=${dt.uid}`"
              class="dropdown-item text-dark"
            >
              <span class="fas fa-eye mr-1"></span>
              See Entries
            </router-link>
            <router-link
              :to="`/cards/${dt.id}/tac?uid=${dt.uid}`"
              class="dropdown-item text-dark"
            >
              <span class="fas fa-eye mr-1"></span>
              See Details
            </router-link>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import { inject, ref, defineComponent } from "vue";
import { apiPost } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "AvailableCardsRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const ToggleAdmin = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/user-account/${
            props.dt.type === "BASIC_USER" ? "add-admin" : "remove-admin"
          }`,
          {
            userId: props.dt.id,
          }
        );
        // eslint-disable-next-line vue/no-mutating-props
        props.dt.type =
          props.dt.type === "BASIC_USER" ? "ADMIN_USER" : "BASIC_USER";
        ShowSuccess();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      ToggleAdmin,
    };
  },
});
</script>

<style scoped></style>
